<template>
  <div
    class="marker-card-detail"
    :class="type ? `marker-card-detail--${type}` : ''"
  >
    <span class="marker-card-detail__label">{{ label }}:</span>
    <span class="marker-card-detail__value">{{ value }}</span>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.marker-card-detail {
  $root: &;
  display: flex;
  flex-wrap: wrap;

  font-size: 12px;
  &__label {
    padding-right: 6px;
  }
  &__value {
    font-weight: bold;
  }

  &--bigger {
    font-size: 15px;
    flex-direction: column;
    margin-bottom: 8px;
  }

  &--bigger & {
    &__label {
      padding-right: 0;
    }
  }

  &--value-bold {
    #{$root} {
      &__label {
        font-weight: bold;
      }
      &__value {
        font-weight: normal;
      }
    }
  }
}
</style>
