<template>
  <div
    class="result"
    :class="{ 'result--map-full-width': isMapFullWidth }"
  >
    <app-loader v-if="loader" />

    <result-controls
      :is-filters.sync="isFilters"
      :is-map-full-width.sync="isMapFullWidth"
      :is-selected-items.sync="isSelectedItems"
      class="result__controls"
    />
    <div
      v-if="isCurrentCriteriaValid"
      class="result__content"
    >
      <div class="result__map">
        <app-map
          :marker-list="markerList"
          :include-marker-selection="isSelectedItems"
        />
      </div>
      <marker-list
        :marker-list="markerList"
        :include-marker-selection="isSelectedItems"
        class="result__list"
      >
        <router-link
          v-if="isSelectedItems"
          class="btn btn--outfly result__list-bottom-btn"
          :to="{ name: 'cart'}"
        >
          zum warenkorb
        </router-link>
      </marker-list>

      <app-filters
        v-if="isFilters && !isSelectedItems"
        class="result__filters"
      />
    </div>
  </div>
</template>

<script>
import AppFilters from '@/components/app-filters';
import AppLoader from '@/components/app-loader';
import AppMap from '@/components/app-map';
import MarkerList from '@/components/marker-list';

import ResultControls from './components/result-controls';

export default {
  components: {
    AppFilters,
    AppLoader,
    AppMap,
    MarkerList,
    ResultControls,
  },
  data() {
    return {
      loader: false,
      isFilters: false,
      isMapFullWidth: false,
      isSelectedItems: false,
    };
  },
  computed: {
    markerList() {
      return this.isSelectedItems
        ? this.$store.getters.cartMarkersList
        : this.$store.getters.filteredMarkers;
    },
    currentCriteriaQuery() {
      return this.$store.getters.currentCriteriaQuery;
    },
    isCurrentCriteriaValid() {
      return Object.values(this.$store.getters.currentCriteriaErrors)
        .filter((err) => err).length === 0;
    },
    isDwmPlanLoaded() {
      return this.$store.getters.actualYears.length > 0;
    },
  },
  watch: {
    currentCriteriaQuery: {
      handler() {
        const query = this.currentCriteriaQuery;
        if (this.isCurrentCriteriaValid) {
          this.$router.replace({ ...this.$route, query })
            .catch((err) => (err.name === 'NavigationDuplicated' ? '' : Promise.reject(err)));
        } else {
          this.$router.replace({ name: 'criteria' }).catch(() => {});
        }
      },
      deep: true,
      immediate: true,
    },
    isDwmPlanLoaded: {
      handler(val) {
        if (val) this.getMarkers();
      },
      immediate: true,
    },
  },
  methods: {
    getMarkers() {
      console.log(this.$store.getters.currentCriteriaErrors, this.isCurrentCriteriaValid);
      if (this.isCurrentCriteriaValid) {
        this.loader = true;
        this.$store.dispatch('loadMarkers')
          .catch((err) => { console.error(err); })
          .finally(() => { this.loader = false; });
      } else {
        console.log('try push to criteria');
        this.$router.replace({ name: 'criteria' }).catch(() => {});
      }
    },
  },
};
</script>

<style lang="scss">
.result {
  $root: &;

  position: relative;
  overflow: hidden;

  height: 100%;
  display: flex;
  flex-direction: column;

  &__controls {
    border-bottom: $panel-border;
  }

  &__content {
    flex: 1 1 auto;
    display: flex;
    overflow: hidden;
    position: relative;
  }

  &__filters {
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    width: 480px;
    max-width: 100%;

    z-index: 5;

    @include media-bp(mob) {
      left: 20px;
      right: 20px;
      width: auto;
      transform: none;
    }
  }

  &__map {
    flex: 0 0 100%;
    max-width: 50%;
    transition: max-width 500ms;

    #{$root}--map-full-width & {
      max-width: 100%;
    }

    @include media-bp(tab) {
      max-width: 0;
    }
  }

  &__list {
    flex: 0 0 100%;
    max-width: 50%;

    border-left: $panel-border;
    background-color: $section-gray-bg;
    position: relative;

    overflow-y: auto;

    @include media-bp(tab) {
      max-width: 100%;
      border-left: none;
    }

    &-bottom-btn {
      width: 100%;
    }
  }
}
</style>
