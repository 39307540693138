<template>
  <div class="app-map">
    <gmap-map
      ref="gmap"
      class="app-map__map"
      style="width: 100%; height: 100%"

      map-type-id="roadmap"

      :center="gmap.center"
      :zoom="gmap.zoom"

      :options="gmap.options"
    >
      <gmap-info-window
        :opened="infoWindow.opened"
        :position="infoWindow.position"

        :options="infoWindow.options"

        @closeclick="closeInfoWindow"
      >
        <marker-infowindow
          v-if="infoWindow.marker"
          :marker="infoWindow.marker"
          @close="closeInfoWindow"
        />
      </gmap-info-window>

      <gmap-cluster
        ref="gmap_clusterer"
        :zoom-on-click="true"
        :batch-size="cluster.batchSize"
        :minimum-cluster-size="cluster.minimumClusterSize"
      >
        <gmap-marker
          v-for="marker in markerList"
          :key="marker.id"
          :position="marker.position"
          :icon="markerIcon(marker)"
          @click="onMarkerClick($event, marker)"
        />
      </gmap-cluster>

      <gmap-marker
        v-if="searchPlace && searchPlace.geometry"
        :position="searchPlace.geometry.location"
        :icon="searchIcon"
      />
    </gmap-map>

    <gmap-autocomplete
      :ref="'autocomplete_input'"
      class="app-map__search-input"
      :component-restrictions="{ country: 'de' }"
      :select-first-on-enter="true"
      :value="initAutocompleteValue"
      placeholder="Strasse suchen"
      @place_changed="setCenter"
    />

    <div
      v-if="infoWindow.opened && infoWindow.marker"
      class="app-map__overlay"
    >
      <div class="app-map__infowindow">
        <span
          class="app-map__infowindow-close icon icon--cros"
          @click="closeInfoWindow"
        />
        <marker-infowindow
          v-if="infoWindow.marker"
          :marker="infoWindow.marker"
          :include-marker-selection="includeMarkerSelection"
          @close="closeInfoWindow"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import GmapCluster from 'vue2-google-maps/dist/components/cluster';

import MarkerInfowindow from '@/components/marker-card/infowindow';

Vue.component('GmapCluster', GmapCluster);

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    region: process.env.VUE_APP_GOOGLE_MAPS_REGION,
    language: process.env.VUE_APP_GOOGLE_MAPS_LANGUAGE,
    libraries: 'places,geometry',
  },
});
export default {
  components: {
    MarkerInfowindow,
  },
  props: {
    markerList: {
      type: Array,
      default: () => [],
    },
    includeMarkerSelection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      google: null,
      mapInstance: null,
      clustererInstance: null,
      gmap: {
        center: {
          lat: 51.223497,
          lng: 6.784315,
        },
        zoom: 11,

        options: {
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
        },
      },

      cluster: {
        batchSize: 2,
        minimumClusterSize: 5,
      },

      infoWindow: {
        marker: null,
        opened: false,
        position: null,
        options: {
          minWidth: 380,
          pixelOffset: {
            width: 0,
            height: -24,
          },
        },
      },

      searchPlace: null,
      autocompleteValue: '',
    };
  },
  computed: {
    mediaByTypes() {
      return this.$store.getters.mediaByTypes;
    },
    cartData() {
      return this.$store.getters.cartData;
    },
    initAutocompleteValue() {
      let initAutocompleteValue = '';
      const { cities } = this.$store.getters.currentCriteria;
      if (!cities?.[0]) return initAutocompleteValue;
      const { ortbez, plz } = cities[0];
      const city = ortbez;
      const zip = plz?.match(/\d{5}/) ? plz : '';

      if (city) initAutocompleteValue += `${city}, `;
      if (zip) initAutocompleteValue += `${zip}, `;

      console.log({ cities }, [city, zip], initAutocompleteValue);

      return initAutocompleteValue;
    },
    searchIcon() {
      return {
        // eslint-disable-next-line max-len
        path: 'M27.648-41.399q0-3.816-2.7-6.516t-6.516-2.7-6.516 2.7-2.7 6.516 2.7 6.516 6.516 2.7 6.516-2.7 2.7-6.516zm9.216 0q0 3.924-1.188 6.444l-13.104 27.864q-.576 1.188-1.71 1.872t-2.43.684-2.43-.684-1.674-1.872l-13.14-27.864q-1.188-2.52-1.188-6.444 0-7.632 5.4-13.032t13.032-5.4 13.032 5.4 5.4 13.032z',
        fillColor: 'orange',
        fillOpacity: 0.8,
        strokeWeight: 1,
        strokeOpacity: 1,
        scale: 0.52,
        anchor: this.google && new this.google.maps.Point(18, 0),
      };
    },
  },
  watch: {
    markerList: {
      handler: 'mapFitMarkers',
      deep: true,
    },
  },
  mounted() {
    this.$refs.gmap.$mapPromise.then((mapInstance) => {
      this.mapInstance = mapInstance;
      this.google = VueGoogleMaps.gmapApi();

      this.mapFitMarkers();
    });

    this.$refs.gmap_clusterer.$clusterPromise.then((clustererInstance) => {
      this.clustererInstance = clustererInstance;
    });
  },
  methods: {
    mapFitMarkers() {
      if (this.google && this.markerList.length) {
        const bounds = this.google && new this.google.maps.LatLngBounds();

        this.markerList.forEach((marker) => bounds.extend(marker.position));

        this.mapFitBounds(bounds);
      }
    },
    mapFitBounds(bounds) {
      this.$refs.gmap.fitBounds(bounds);
      this.repaintClusters();
    },
    mapSetCenter(center) {
      this.$refs.gmap.$mapObject.setCenter(center);
      this.repaintClusters();
    },
    repaintClusters() {
      if (this.mapInstance && this.clustererInstance) {
        this.google.maps.event.addListenerOnce(this.mapInstance, 'idle', () => {
          this.clustererInstance.repaint();
        });
      }
    },
    markerIcon(marker) {
      const url = this.cartData[marker.id]
        ? this.mediaByTypes[marker.tp].mappin_active
        : this.mediaByTypes[marker.tp].mappin;

      return { url };
    },
    onMarkerClick($event, marker) {
      if (marker.id === this.infoWindow.marker?.id) {
        this.closeInfoWindow();
      } else {
        this.openInfoWindow($event, marker);
      }
    },
    openInfoWindow($event, marker) {
      this.infoWindow.marker = marker;
      this.infoWindow.opened = true;
      this.infoWindow.position = $event.latLng;
    },
    closeInfoWindow() {
      this.infoWindow.marker = null;
      this.infoWindow.opened = false;
      this.infoWindow.position = null;
    },
    setCenter(place) {
      this.searchPlace = place;
      if (this.searchPlace.formatted_address) {
        this.autocompleteValue = this.searchPlace.formatted_address;
      }

      if (place && place.geometry !== undefined) {
        this.mapSetCenter(place.geometry.location);
        if (place.geometry.viewport !== undefined) {
          this.mapFitBounds(place.geometry.viewport);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.app-map {
  width: 100%;
  height: 100%;

  position: relative;

  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $modal-backdrop-color-light;
    padding: 20px;

    display: none;
    @include media-bp(mob) {
      display: block;
    }
  }

  &__infowindow {
    position: absolute;
    top: 15px;
    bottom: 15px;
    left: 15px;
    right: 15px;

    background-color: $results-marker-infowindow-bg;

    padding: 20px 10px;

    &-close {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 16px;
      cursor: pointer;

      &:hover {
        font-size: 18px;
      }
    }

    .marker-infowindow {
      max-width: 100%;
      width: 100%;
      height: 100%;
    }
  }

  &__search-input {
    @include input;

    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 20px);
    max-width: 480px;
  }

  .gm-style-iw-t {
    @include media-bp(mob) {
      visibility: hidden;
    }
  }
}
</style>
