<template>
  <div class="marker-info">
    <marker-card-detail
      v-if="pps"
      label="Kontaktchancen"
      :value="pps | thousands"
      type="value-bold"
    />
    <marker-card-detail
      label="Ort"
      :value="city"
    />
    <marker-card-detail
      label="Ortsteil"
      :value="district"
    />
    <marker-card-detail
      v-if="block"
      label="Block"
      :value="block"
    />
    <marker-card-detail
      label="Standortnummer"
      :value="locationNumber"
    />
    <marker-card-detail
      label="Beleuchtung"
      :value="backlight"
    />
  </div>
</template>

<script>
import MarkerCardDetail from './detail';

export default {
  components: {
    MarkerCardDetail,
  },
  props: {
    marker: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    city() {
      console.log(this.marker);
      return `${this.marker.plz}, ${this.marker.ona}`;
    },
    district() {
      return this.marker.otl.trim() || '-';
    },
    locationNumber() {
      return `${this.marker.sto}\u2011${this.marker.postis}`;
    },
    backlight() {
      return this.marker.bl ? 'JA' : 'NEIN';
    },
    block() {
      return this.marker.block;
    },
    pps() {
      return this.marker.pps;
    },
  },
};
</script>

<style>

</style>
