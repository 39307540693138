<template>
  <div class="app-filters">
    <app-input
      v-model="address"
      label="Adresse:"
      reset-button
      placeholder="Filter Adresse..."
    />
    <app-input
      v-model="district"
      label="Ortsteil:"
      reset-button
      placeholder="Filter Ortsteil..."
    />
    <app-input
      v-model="zip"
      label="PLZ:"
      reset-button
      placeholder="Filter PLZ..."
    />
    <app-input-radio
      v-model="backlight"
      label="Beleuchtet:"
      :options="backlightOptions"
    />
  </div>
</template>

<script>
import AppInput from '@/components/app-input';
import AppInputRadio from '@/components/app-input-radio';

export default {
  components: {
    AppInput,
    AppInputRadio,
  },
  data() {
    return {
      backlightOptions: [
        { label: 'JA', value: 'yes' },
        { label: 'NEIN', value: 'no' },
        { label: 'EGAL', value: undefined },
      ],
    };
  },
  computed: {
    address: {
      get() { return this.$store.getters.filters.address; },
      set(address) { this.$store.dispatch('updateFilters', { address }); },
    },
    district: {
      get() { return this.$store.getters.filters.district; },
      set(district) { this.$store.dispatch('updateFilters', { district }); },
    },
    zip: {
      get() { return this.$store.getters.filters.zip; },
      set(zip) { this.$store.dispatch('updateFilters', { zip }); },
    },
    backlight: {
      get() { return this.$store.getters.filters.backlight; },
      set(backlight) { this.$store.dispatch('updateFilters', { backlight }); },
    },
  },
};
</script>

<style lang="scss">
.app-filters {
  $root: &;
  padding: 20px;
  background-color: $panel-bg;
  border: $panel-border;
  box-shadow: $panel-box-shadow;

  display: flex;
  flex-direction: column;

  .app-input {
    margin-bottom: 12px;
  }
}
</style>
