<template>
  <div class="app-input-radio">
    <div
      v-if="label"
      class="app-input-radio__label"
    >
      {{ label }}
    </div>
    <div class="app-input-radio__controls">
      <div
        v-for="(option, index) in options"
        :key="index"
        class="app-input-radio__option"
      >
        <input
          :id="`${_uid}_${index}`"
          v-model="localValue"
          type="radio"
          :name="_uid"
          :value="option.value"
        >
        <label
          :for="`${_uid}_${index}`"
          class="app-input-radio__label"
        >{{ option.label }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Boolean],
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    default: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    localValue: {
      get() { return this.value || this.default; },
      set(value) { this.$emit('input', value); },
    },
  },
};
</script>

<style lang="scss">
.app-input-radio {
  display: flex;
  flex-direction: column;
  font-size: 18px;

  &__controls {
    display: flex;
    flex-flow: row wrap;
    margin: 12px 0;
  }

  &__option {
    input {
      display: none;
    }

    label {
      opacity: .5;
      font-weight: 700;
      cursor: pointer;
      padding: 5px;
      color: $input-color;
      border: $input-border;
      border-color: transparent;
      display: inline-block;
      text-align: center;

      &:hover {
        color: $input-result-color;
        opacity: 1;
      }
    }

    input:checked + label {
      background-color: $input-bg;
      border-color: $input-border-color;
      opacity: 1;
      color: $input-result-color;
    }
  }
}
</style>
