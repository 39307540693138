<template>
  <article class="marker-infowindow">
    <div class="marker-infowindow__header">
      <app-media-image :type="marker.tp" />
      <div class="marker-infowindow__title">
        {{ marker.adr }}
      </div>
    </div>

    <div
      v-if="block"
      class="marker-infowindow__row marker-infowindow__row"
    >
      <div class="marker-infowindow__box">
        <marker-card-detail
          label="Tagespreis"
          :value="dailyPrice | euro"
          :type="'bigger'"
          class="marker-infowindow__price"
        />
      </div>
      <div class="marker-infowindow__box">
        <img
          class="marker-infowindow__photo"
          :src="photo"
          @click="openPhoto"
        >
      </div>
      <div class="marker-infowindow__box">
        <marker-card-detail
          label="Summe"
          :value="sum | euro"
          :type="'bigger'"
          class="marker-infowindow__sum"
        />
      </div>
    </div>

    <div class="marker-infowindow__row marker-infowindow__row--details">
      <div class="marker-infowindow__box marker-infowindow__box--details">
        <marker-info :marker="marker" />

        <div class="marker-infowindow__controls">
          <button
            class="btn btn--small btn--secondary"
            @click="$emit('close')"
          >
            Schließen
          </button>
        </div>
      </div>

      <div class="marker-infowindow__box marker-infowindow__box--selection">
        <marker-card-detail
          v-if="block"
          label="Verfügbarkeit"
        />
        <marker-selection
          :marker="marker"
          :full-info="true"
          :info-as-popup="false"
          :dwm-plan="criteriaDwmPlan"
          :include-marker-selection="includeMarkerSelection"
        />
      </div>
    </div>
  </article>
</template>

<script>
import eventbus from '@/utils/eventbus';

import AppMediaImage from '@/components/app-media-image';
import MarkerSelection from '@/components/marker-selection';
import MarkerCardDetail from './components/detail';
import MarkerInfo from './components/info';

export default {
  components: {
    AppMediaImage,
    MarkerCardDetail,
    MarkerInfo,
    MarkerSelection,
  },
  props: {
    marker: {
      type: Object,
      default: () => {},
    },
    includeMarkerSelection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    block() {
      return this.marker.block;
    },
    dailyPrice() {
      return this.marker.prs;
    },
    sum() {
      return this.$store.getters.getMarkerCartSum(this.marker);
    },
    photo() {
      return this.marker._serviceAttributes.image;
    },
    criteriaDwmPlan() {
      return this.$store.getters.criteriaDwmPlan;
    },
  },
  methods: {
    openPhoto() {
      eventbus.$emit('open-image', this.photo);
    },
  },
};
</script>

<style lang="scss">
.marker-infowindow {
  $root: &;
  padding: 10px 10px 0;
  line-height: 1.6;

  max-width: 450px;
  min-width: 300px;
  height: 300px;

  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-bottom: 4px;

    .app-media-image {
      height: 1.5em;
      margin-right: 8px;
    }
  }

  &__title {
    color: $results-marker-infowindow-title-color;
    text-transform: uppercase;
    font-weight: 600;

    @include media-bp(tab) {
      font-size: 16px;
    }
  }

  &__controls {
    display: flex;
    padding: 12px 0 0;
  }

  &__sum {
    color: $results-marker-infowindow-sum-color;
    text-align: right;
  }

  &__row {
    overflow: hidden;
    display: flex;
    margin: 0 -5px;

    &--details {
      flex: 1 1;
      padding-top: 12px;
    }
  }

  &__box {
    flex: 1 1 170px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 0 5px;

    &--details {
      justify-content: flex-start;
    }

    &--selection {
      justify-content: flex-start;
      padding-bottom: 12px;
    }

    .marker-selection {
      overflow: auto;
      width: 100%;
    }

    .marker-info {
      flex: 1 0 auto;
    }
  }

  &__photo {
    object-fit: contain;
    object-position: center;
    width: 100%;
    max-height: 60px;
    cursor: pointer;
  }
}
</style>
