<template>
  <article
    class="marker-card"
    :class="{ 'marker-card--touch': isTouchDevice }"
  >
    <div class="marker-card__header">
      <app-media-image :type="marker.tp" />
      <div class="marker-card__title">
        {{ marker.adr }}
      </div>
      <span
        class="icon icon--image"
        @click="openPhoto"
      />
    </div>

    <div class="marker-card__body">
      <div class="marker-card__box marker-card__box--left">
        <marker-card-detail
          v-if="block"
          label="Tagespreis"
          :value="dailyPrice | euro"
          :type="'bigger'"
        />

        <marker-info :marker="marker" />
      </div>
      <div class="marker-card__box marker-card__box--center">
        <marker-card-detail
          v-if="block"
          label="Verfügbarkeit"
          :type="'bigger'"
        />
        <marker-selection
          :marker="marker"
          class="marker-card__selection"
          :dwm-plan="criteriaDwmPlan"
          :include-marker-selection="includeMarkerSelection"
        />
        <marker-card-detail
          v-if="block"
          label="Summe"
          :value="sum | euro"
          :type="'bigger'"
          class="marker-card__detail marker-card__detail--sum"
        />
        <app-media-image
          :type="marker.tp"
          image-type="image_banner"
        />
      </div>
      <div class="marker-card__box marker-card__box--right">
        <marker-card-detail
          v-if="block"
          label="Summe"
          :value="sum | euro"
          :type="'bigger'"
          class="marker-card__detail marker-card__detail--sum"
        />
        <img
          class="marker-card__photo"
          :src="photo"
          @click="openPhoto"
        >
      </div>
    </div>
  </article>
</template>

<script>
import eventbus from '@/utils/eventbus';

import isTouchDevice from '@/helpers/isTouchDevice';

import AppMediaImage from '@/components/app-media-image';
import MarkerSelection from '@/components/marker-selection';
import MarkerCardDetail from './components/detail';
import MarkerInfo from './components/info';

export default {
  components: {
    AppMediaImage,
    MarkerCardDetail,
    MarkerInfo,
    MarkerSelection,
  },
  props: {
    marker: {
      type: Object,
      default: () => {},
    },
    includeMarkerSelection: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    block() {
      return this.marker.block;
    },
    dailyPrice() {
      return this.marker.prs;
    },
    sum() {
      return this.$store.getters.getMarkerCartSum(this.marker);
    },
    photo() {
      return this.marker._serviceAttributes.image;
    },
    isTouchDevice() {
      return isTouchDevice();
    },
    criteriaDwmPlan() {
      return this.$store.getters.criteriaDwmPlan;
    },
  },
  methods: {
    openPhoto() {
      eventbus.$emit('open-image', this.photo);
    },
  },
};
</script>

<style lang="scss">
.marker-card {
  $root: &;

  display: flex;
  flex-direction: column;

  padding: 10px;
  line-height: 1.6;

  &__header {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-bottom: 4px;

    .app-media-image {
      height: 1.5em;
      margin-right: 8px;
    }
    .icon {
      color: $results-marker-card-icon-color;
      margin-left: auto;
      padding-left: 8px;
      cursor: pointer;

      display: none;
      @include media-bp(mob) {
        display: block;
      }
    }
  }

  &__title {
    color: $results-marker-card-title-color;
    text-transform: uppercase;
    font-weight: 600;
  }

  &__detail {
    &--sum {
      color: $results-marker-card-sum-color;

      #{$root}__box--center & {
        display: none;
        @include media-bp(mob) {
          display: flex;
        }
      }
    }
  }

  &__body {
    flex: 1 1 auto;
    display: flex;
    margin: 0 -5px;
  }

  &__box {
    flex: 1 1 170px;

    display: flex;
    flex-direction: column;

    padding: 0 5px;

    &--center {
      flex: 0 0 auto;
      text-align: center;
      position: relative;

      @include media-bp(mob) {
        text-align: left;
      }

      .app-media-image {
        height: 70px;
        margin-top: auto;
        margin-bottom: 12px;

        #{$root}--touch & {
          display: none;
        }

        @include media-bp(mob) {
          display: none;
        }
      }
    }

    &--right {
      align-items: flex-end;
      text-align: right;

      @include media-bp(mob) {
        display: none;
      }
    }
  }

  &__selection {
    flex: 1 1 auto;
    max-height: 100%;
    margin-bottom: 8px;
  }

  &__photo {
    object-fit: contain;
    object-position: right;
    width: 100%;
    max-height: 125px;
    cursor: pointer;
  }
}
</style>
