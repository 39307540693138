<template>
  <RecycleScroller
    class="marker-list"
    :class="{ 'marker-list--updating': animateListUpdating }"
    :items="markerList"
    :min-item-size="itemHeight"
    key-field="id"
  >
    <template v-slot="{ item }">
      <marker-card
        :marker="item"
        class="marker-list__item"
        :include-marker-selection="includeMarkerSelection"
        :style="{ height: `${itemHeight}px`}"
      />
    </template>

    <template #after>
      <div
        v-if="$slots.default"
        class="marker-list__bottom"
      >
        <slot />
      </div>
    </template>
  </RecycleScroller>
</template>

<script>
import MarkerCard from '@/components/marker-card';

import Vue from 'vue';
import VueVirtualScroller from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

Vue.use(VueVirtualScroller);

export default {
  components: {
    MarkerCard,
  },
  props: {
    markerList: {
      type: Array,
      default: () => [],
    },
    includeMarkerSelection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      itemHeight: 270,
      animateListUpdating: false,
    };
  },
  watch: {
    markerList: {
      handler: 'handleListChange',
      deep: true,
    },
  },
  mounted() {
    console.log('THIS', this);
  },
  methods: {
    handleListChange() {
      this.animateListUpdating = true;
      setTimeout(() => {
        this.animateListUpdating = false;
      }, 200);
    },
  },
};
</script>

<style lang="scss">
.marker-list {
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $panel-bg;
  }
  &--updating {
    position: relative;
    &::after{
      content: '';
    }
  }
  &__item {
    border-bottom: $panel-border;
    border-bottom-style: dotted;
    margin-top: 10px;

    overflow: hidden;
  }
  &__bottom {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
  }
}
</style>
