<template>
  <div class="result-controls">
    <div class="result-controls__group result-controls__group--map">
      <label class="result-controls__btn btn btn--small btn--secondary">
        <input
          v-model="showMapFullWidth"
          type="checkbox"
        >
        <span
          v-if="showMapFullWidth"
          class="icon icon--arrow-left"
        />
        <span>Karte</span>
        <span
          v-if="!showMapFullWidth"
          class="icon icon--arrow-right"
        />
      </label>
    </div>

    <div class="result-controls__group result-controls__group--filters">
      <label
        v-if="!showSelectedItems"
        class="result-controls__btn btn btn--small"
        :class="{'btn--secondary': !filtersCount}"
      >
        <input
          v-model="showFilters"
          type="checkbox"
        >
        <span>Filter</span>
        <span
          class="icon"
          :class="`icon--arrow-${ showFilters ? 'up' : 'down' }`"
        />
      </label>
    </div>

    <div class="result-controls__group result-controls__group--show-selected">
      <label
        class="result-controls__btn btn btn--small"
        :class="showSelectedItems ? 'btn--secondary' : 'btn--active'"
      >
        <input
          v-model="showSelectedItems"
          type="radio"
          name="show-selected"
          :value="false"
        >
        <span>Suche ({{ markersCount }})</span>
      </label>
      <label
        class="result-controls__btn btn btn--small"
        :class="showSelectedItems ? 'btn--active' : 'btn--secondary'"
      >
        <input
          v-model="showSelectedItems"
          type="radio"
          name="show-selected"
          :disabled="!selectedCount"
          :value="true"
        >
        <span>Auswahl ({{ selectedCount }})</span>
      </label>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    isFilters: {
      type: Boolean,
      default: false,
    },
    isMapFullWidth: {
      type: Boolean,
      default: false,
    },
    isSelectedItems: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showFilters: {
      get() { return this.isFilters; },
      set(value) { this.$emit('update:isFilters', value); },
    },
    showMapFullWidth: {
      get() { return this.isMapFullWidth; },
      set(value) { this.$emit('update:isMapFullWidth', value); },
    },
    showSelectedItems: {
      get() { return this.isSelectedItems; },
      set(value) { this.$emit('update:isSelectedItems', value); },
    },
    markersCount() {
      return this.$store.getters.filteredMarkers.length;
    },
    filtersCount() {
      return this.$store.getters.filtersCount;
    },
    cartTotalData() {
      return this.$store.getters.cartTotalData;
    },
    selectedCount() {
      const selectedCount = this.cartTotalData.markersCount;
      if (selectedCount === 0) {
        this.$emit('update:isSelectedItems', false);
      }
      return selectedCount;
    },
  },
  methods: {
    toggleMap() {
      this.showMapFullWidth = !this.showMapFullWidth;
    },
    toggleSelectedItems() {
      this.showSelectedItems = !this.showSelectedItems;
    },
  },
};
</script>

<style lang="scss">
.result-controls {
  $root: &;

  padding: 5px;
  display: flex;
  position: relative;

  &__group {
    padding: 5px;
    flex: 1 1 33.3333%;
    max-width: 33.3333%;

    display: flex;

    &--filters {
      justify-content: center;
    }

    &--show-selected {
      justify-content: flex-end;
    }
  }

  &__btn {
    input {
      display: none;
    }

    .icon {
      margin: 0 8px;
      &:last-child {
        margin-right: 0;
      }
    }
    input + .icon {
      margin-left: 0;
    }

    #{$root}__group--filters & {
      cursor: pointer;
    }
  }

  @include media-bp(tab) {
    flex-wrap: wrap-reverse;

    &__group {
      flex: 1 1 auto;
      max-width: 100%;
    }
  }

  @include media-bp(mob) {
    &__group {
      &--filters {
        justify-content: flex-end;
      }
    }
  }
}
</style>
